.header {
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 10rem;
    height: 3rem;
}

.header-menu {
    font-size: 18px;
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}

.header-menu>li:hover {
    color: var(--orange);
    cursor: pointer;
    font-weight: bold;
}

@media screen and (max-width: 767px) {
    .header>:nth-child(2) {
        position: fixed;
        right: 2rem;
        z-index: 99;
    }

    .header-menu {
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
}
/* 

.topnav {
    overflow: hidden;
    list-style: none;
    background-color: #333;
}

.topnav>ul li {
    list-style: none;
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav a:hover {
    background-color: #ddd;
    color: black;
}

.topnav a.active {
    background-color: #04AA6D;
    color: white;
}

.topnav .icon {
    display: none;
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {
        display: none;
    }

    .topnav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {
        position: relative;
    }

    .topnav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
} */